.appointments-list-container{
    margin-left: 20%;
    padding-top: 5em;
    margin-right: 30%;
}

.appointments-list-heading{
    margin-top: 0;
}

.appointment-card-list-container{
    padding-top: 5em;
}

.appointment-list-mentor-mentee-dropdown{
    padding: 0.5em;
    font-size: 1em;
    border: 1px black solid;
    /* color: #0057D2; */
    background-color: white;
    
}