.signup-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-header{
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: 2.5em;
    font-family: sans-serif;
    color: #0057D2;
}

.signup-form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-input{
    height: 0.4em;
    width: 14em;
    margin: 1em;
    border-radius: 4em;
    font-size: 1.2em;
    padding: 1em;
}

.signup-input:focus, .signup-button:focus{
    outline: none;
    box-shadow: 0 0 0.05em 0.07em #0057D2;
}


.signup-button{
    background-color: #0057D2;
    color: white;
    border-radius: 4em;
    height: 2em;
    font-size: 1.2em;
    margin: 1em;
    width: 5em;

}

.is-mentor-container{
    display: flex;
}

.is-mentor-checkbox{
    height: 20px;
    width: 20px;
    margin: 1px;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1.5em;
}

.signup-success-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-success-redirection-msg{
    font-size: 1.2em;
}
.signup-success-login-link{
    color: #0057D2;
    font-family: 'Times New Roman', Times, serif;
    
}