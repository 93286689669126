.mentor-signup-container{
    margin-top: 1.5em;
}
.domain-select-container{
    margin: 1em;
}

.domain-label{
    font-size: 1.2em;
}

.domain-select{
    height: 2em;
    width: 70%;
    font-size: 1em;
    border-radius: 0.2em;
}

.availability-schedule-signup-header{
    margin-top: 2em;
}