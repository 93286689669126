.profile-container{
    /* background-color: lightgreen; */
    padding-top: 4em;
    font-family:  sans-serif;
    width: 100%;
}

.profile-base-container,
.profile-experience-container
{
    /* background-color: lightpink; */
    box-shadow: 0px 2px 5px -5px rgb(0, 0, 0);
}

.basic-details-container{
    display: flex;
    flex-direction: row;
}

.profile-picture-container, 
.intro-label,
.profile-domain-label,
.profile-experience-label,
.profile-availability-schedule-label
{
    flex-basis: 20%;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: center;
}

.profile-picture{
    background-color: lightslategray;
    height: 12em;
    width: 12em;
    border: black 1px solid;
    border-radius: 0.5em;
}

.basic-details{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2em;
    padding-left: 0;
    margin-left: 2em;
    font-size: 1.2em;
}

.intro-details-container,
.profile-domain-container
{
    margin-top: 2em;

}

.intro-container,
.profile-domain-container,
.profile-experience-container,
.profile-availability-schedule-container
{
    display: flex;
    flex-direction: row;
}

.intro-para{
    border: 1px black solid;
    border-radius: 0.5em;
    padding: 1em;
    margin-left: 2em ;
    width: 50%;
    line-height: 1.6;
}

.profile-domain-para,
.experience-job-container,
.user-availability-schedule
{
    margin-left: 2em;
}

.profile-experience-container,
.profile-availability-schedule-container
{
    padding-top: 1em;
}

.disable-pointer{
    pointer-events: none;
}

.schedule-call-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 20%;
}

.schedule-call-button{
    background-color: #0057D2;
    color: white;
    font-size: 1em;
    height: 25%;
    border-radius: 0.5em;
    padding: 0.7em;
}

.schedule-call-button:focus{
    outline: none;
    box-shadow: 0 0 0.05em 0.07em #0057D2;
}