.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-header{
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: 2.5em;
    font-family: sans-serif;
    color: #0057D2;
}

.login-form-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-input{
    height: 0.4em;
    width: 14em;
    margin: 1em;
    border-radius: 4em;
    font-size: 1.2em;
    padding: 1em;
}

.login-input:focus, .login-button:focus{
    outline: none;
    box-shadow: 0 0 0.05em 0.07em #0057D2;
}

.login-button{
    background-color: #0057D2;
    color: white;
    border-radius: 4em;
    height: 2em;
    font-size: 1.2em;
    margin: 1em;
    width: 5em;

}


.signup-login-redirect-msg{
    font-size: 1em;
}

.signup-login-link{
    color: #0057D2;
    
}