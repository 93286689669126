.profile-card-container{
    border: solid black 2px;
    max-width: 12em;
    display: flex;
    flex-direction: column;
    border-radius: 0.4em;
    padding: 2em;
    margin-top: 2em;
    margin: 2em;
    cursor: pointer;

}

.profile-card-base-details-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-card-picture{
    background-color: rgb(148, 149, 150);
    height: 4em;
    width: 6em;
    
}

.profile-card-name{
    margin-left: 1em;
}

.profile-card-domain-container{
    display: flex;
    justify-content: center;
    margin-top: 1em;
}


.profile-card-domain{
    margin-left: 1em;
}
a {
    text-decoration: inherit;
    color: inherit;
}
