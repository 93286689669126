.scheduler-container{
    /* border: 1px solid black; */
    padding-top: 5em;
    display: flex;
    justify-content:space-between;
    width: 100%;
}

.call-with-intro{
    margin-top: 2em;
    width: 20%;
    font-size: 1.2em;
}

.scheduler-select-date-time-container{
    display: flex;
    justify-content: space-around;
    width: 80%;
}

.select-date-heading,
.select-time-heading{
    margin: 1.5em;
    font-weight: 550;
    font-size: 1.1em;
}

.scheduler-select-time{
    width: 50%;
}